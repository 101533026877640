.owlcarousel-sec-classss {
    background-image: url("../../../assets/images/carsoul.png");
    background-repeat: no-repeat;
    object-fit: cover;
}

.dispute {
    color: #F8961D;
    font-size: 25px;
    font-weight: 600;
}

p.text-white.fs-5.mt-2.mb-4.adipisicing {
    color: #FFFFFF;
    font-size: 18px;
    font-weight: 300;
}



.post-slide {
    background-color: #ffffff7a;
    width: 100%;
    max-width: 400px;
    padding: 40px;
}

.owlcarousel-sec-classss {
    background-color: rgb(3, 47, 89);
    height: 100vh;
    display: flex;
    align-items: center;
}

.owl-nav {
    float: right;
    font-size: 28px
}

.owl-carousel .owl-nav button.owl-next,
.owl-carousel .owl-nav button.owl-prev,
.owl-carousel button.owl-dot {
    background: 0 0;
    color: inherit;
    border: none;
    padding: 5px 13px !important;
    margin-top: 20px;
    margin-right: 10px;
    font: inherit;
    background: white;
    border-radius: 50%;
}

.owl-controls .owl-buttons .owl-prev,
.owl-controls .owl-buttons .owl-next {
    background: #fff;
    padding: 10px;
    border-radius: 50%;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    margin: 0 5px;
}

.owl-controls .owl-buttons .owl-prev i,
.owl-controls .owl-buttons .owl-next i {
    color: #333;
    font-size: 20px;
}

@media only screen and (max-width: 1280px) {
    .post-slide .post-content {
        padding: 0px 15px 25px 15px;
    }
}