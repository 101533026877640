.field-icon {
  float: right;
  margin-left: -25px;
  margin-top: -31px;
  position: relative;
  z-index: 2;
  font-size: 24px;
  margin-right: 8px;
}

.invalid-feedback {
  font-size: 15px;
}

.password-valid {
  color: cadetblue;
}

.update-password-modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  background: #0000003b;
  border-radius: 10px;
  width: 100%;
  height: 100%;
}

.box-body {
  padding: 20px;
}

.password-modal {
  width: 40em;
  margin: 0 auto;
  position: relative;
  flex-direction: column;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
  top: 10px;
  overflow-y: scroll;
  height: 350px;
}

.password-cancel-btn {
  background: #201d1e;
  color: #fff;
  padding: 7px 21px 7px 22px;
  border: 1px solid;
}

.password-update-btn {
  color: #fff;
  background: #201d1e;
  padding: 7px 5px 7px 5px;
  border: 1px solid;
}

.password-update-btn {
  color: #fff;
  padding: 7px 10px 7px 10px;
  background: #292627;

  border: 1px solid;
}

.password-updation {
  color: #fff;
  padding: 4px 40px 4px 40px;
  background: #292627;
  font-size: 16px;
  border: 1px solid;
}

@media (max-width: 768px) {
  .password-modal {
    width: 98% !important;
    right: 0 !important;
    top: 100px;
  }

  .password-update-btn {
    margin-right: 130px !important;
  }
}
