.content-header {

    padding: 15px 15px 0 15px;
}

.content-header>h1>small {
    font-size: 15px;
    display: inline-block;
    padding-left: 13px;
    font-weight: 300;
}

.content-header>.breadcrumb {
    float: right;
    background: transparent;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 12px;
    padding: 7px 5px;
    position: absolute;
    top: 15px;
    right: 10px;
    border-radius: 2px;
}

.active {
    color: #777;
}

/* second section */

.content {
    min-height: 250px;
    padding: 15px;
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
}

.row {
    margin-right: -15px;
    margin-left: -15px;
}

.nav-tabs-custom {
    margin-bottom: 20px;
    background: #fff;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
}

.nav-tabs-custom>.nav-tabs {
    margin: 0;
    border-bottom-color: #f4f4f4;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    background: #222d32;
}

.nav-tabs {
    border-bottom: 1px solid #ddd;
}

.nav {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.nav-tabs-custom>.nav-tabs>li:first-of-type {
    margin-left: 0;
}

.nav-tabs-custom>.nav-tabs>li.active {
    border-top-color: #73a8c1;
}

.nav-tabs-custom>.nav-tabs>li:first-of-type.active>a {
    background: #73a8c1;

}

.nav-tabs-custom>.nav-tabs>li.active>a {
    border-top-color: transparent;
    border-left-color: #f4f4f4;
    border-right-color: #f4f4f4;
}

.nav-tabs-custom>.nav-tabs>li.active>a,
.nav-tabs-custom>.nav-tabs>li.active:hover>a {
    background-color: #73a8c1;
    color: #fff;
    cursor: pointer;
}

.nav-tabs-custom>.nav-tabs>li>a,
.nav-tabs-custom>.nav-tabs>li>a:hover {
    background: transparent;
    margin: 0;
}

.nav-tabs>li.active>a,
.nav-tabs>li.active>a:focus,
.nav-tabs>li.active>a:hover {
    color: #555;
    cursor: default;
    background-color: #fff;
    border: 1px solid #ddd;
    border-bottom-color: transparent;
}

.nav-tabs>li>a {
    margin-right: 2px;
    line-height: 1.42857143;
    border: 1px solid transparent;
    border-radius: 4px 4px 0 0;
}

.nav>li>a {
    position: relative;
    display: block;
    padding: 10px 15px;
}

.nav-tabs-custom>.tab-content {
    background: #fff;
    padding: 10px;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

.tab-content>.active {
    display: block;
}

.bg-aqua {
    background-color: #F4F4F4 !important;
    border: 1px solid #232323;
    border-radius: 6px !important;
}


.small-box {
    border-radius: 2px;
    position: relative;
    display: block;
    margin-bottom: 20px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}

.small-box>.inner {
    padding: 10px;
}

.small-box h3 {
    font-size: 38px;
    font-weight: bold;
    margin: 0 0 10px 0;
    white-space: nowrap;
    padding: 0;
}

.small-box h3,
.small-box p {
    z-index: 5;
}

.small-box p {
    font-size: 15px;
    color: #000;
}

p {
    margin: 0 0 10px;
}


.icon img {
    width: 82px;
    height: 100px;
}


.small-box .icon {
    transition: all .3s linear;
    position: absolute;
    top: 6px;
    right: 60px;
    /* height: 200px !important; */
    z-index: 0;
    /* font-size: 185px; */
    color: rgba(0, 0, 0, 0.15);
}

.mediation a {
    color: white;

}

.pull-left {
    float: left !important;
}

.btn-primary {
    background-color: #73a8c1;
    border-color: #73a8c1;
    color: #000 !important;
}




.box.box-danger {
    border-top-color: #dd4b39;
}

.box-header {
    color: #444;
    display: block;
    padding: 10px;
    position: relative;
}

.todo-list {
    margin: 0;
    padding: 0;
    list-style: none;
    overflow: auto;
}

.todo-list>li {
    border-radius: 2px;
    padding: 10px;
    background: #f4f4f4;
    margin-bottom: 2px;
    border-left: 2px solid #e6e7e8;
    color: #444;
}

.todo-list>li .text {
    display: inline-block;
    margin-left: 5px;
    font-weight: 600;
}

.todo-list>li .tools {
    display: none;
    float: right;
    color: #dd4b39;
}

.icns-notication {
    font-size: 25px;

}

.view-all-btn {
    padding: 10px;
    font-size: 14px;
    line-height: normal;
    color: #fff;
    margin: 8px 5px;
    border: 0;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 500;
    background: #004281;
    display: inline-flex;
    width: auto;
    justify-content: center;
}