img.imageClass {
    width: 18%;
}

img.linkimageClass {
    width: 18%;
}

ul.landing-page-ul li {
    color: black;
    text-transform: capitalize;
    font-size: 15px;
}

.truce-cal-heading {
    background-image: url("../../../assets/images/Main-image.png");
    height: 500px;
    background-repeat: no-repeat;
    object-fit: cover;
    padding-top: 103px;
    margin-top: -116px;
    background-size: cover;
}

/* .truce-cal-heading {
    background-image: url("../../../assets/images/Main-image.png");
    height: 500px;
    background-repeat: no-repeat;
    object-fit: cover;
    padding-top: 90px;
} */

@media only screen and (max-width: 768px) {}


.login-register-btn {
    background: #083266;
    color: #fff;
    border: 1px solid;
    border-radius: 8px;
    padding: 0px 11px 0 13px;
}

.truce-cal-heading {
    /* text-align: center; */
    justify-content: center;
    /* padding-top: 45px; */
}

.truce-call h1 {
    font-weight: bold;
    font-size: 35px;
    color: #232323;
    padding-top: 50px;
}

.toggle-button {
    display: none;
}

.link-img {
    margin: 0px;
}



@media (max-width: 1004px) {
    img.linkimageClass {
        width: 8%;
    }

    .header-fix {
        text-align: left !important;
        padding-left: 10px;

    }

    a.login-conariner-rights.mx-3.link-img {
        margin: 0px !important;
    }

    .truce-cal-heading {
        background-image: url("../../../assets/images/Main-image.png");
        height: 650px;
        background-repeat: no-repeat;
        object-fit: cover;
        padding-top: 30px;
    }

    .toggle-button {
        display: inline;
        /* position: absolute; */
        /* top: 0px !important; */
        /* right: 20px; */
        font-size: 24px;
        cursor: pointer;
        z-index: 1000;
        background: none;
        border: none;
        FLOAT: RIGHT;
    }


    /* .toggle-button {
        display: block;
        position: absolute;
        top: 0px !important;
        right: 20px;
        font-size: 24px;
        cursor: pointer;
        z-index: 1000;
        background: none;
        border: none;
        color: #000;
    } */

    img.imageClass {
        width: 40%;
    }

    .toggle-button:focus {
        outline: none;
    }

    .toggle-button.close-btn {
        position: absolute;
        top: 0px;
        right: 20px;
        font-size: 24px;
        cursor: pointer;
        z-index: 1000;
        background: none;
        border: none;
        color: #000;
    }

    .toggle-button.close-btn:focus {
        outline: none;
    }

    .landing-page-ul {
        display: none;
        position: absolute;
        top: 80px;
        left: 0;
        width: 100%;
        background-color: #fff;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 999;
        text-align: center;
    }

    .landing-page-ul.show {
        display: block;
    }

    .landing-page-ul li {
        padding: 15px 0;
        border-bottom: 1px solid #ccc;
    }
}

.header-fix {
    /* position: fixed; */
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    transition: background-color 0.3s ease;
    text-align: center;
    opacity: 1.1;
}


/* .header-fix.scrolled {
    background-color: #d7e5f1;
}

*/
.landing-page-ul .active li {
    font-weight: bold;
}

.scroll-background .header-fix {
    background-color: #000 !important;

}

.scroll-background {
    background-color: #d3e1ee !important;
}

/* navbar.css */
.header-fix {
    transition: background-color 0.3s ease;
}

.scroll-background {
    background-color: #d3e1ee;
    /* Example: Change background color */
}

.header-fix {

    top: 0;
    width: 100%;

}

.scroll-background {
    background-color: #21568a !important;
    transition: background-color 0.3s;
}

.list-header {
    margin: 0px !important;
}