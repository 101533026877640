body {
  font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial,
    sans-serif !important;
}

.app-main-header {
  background-color: #083266 !important;
  color: #fff !important;
}

.law-mediation-header {
  background-color: #9f5903 !important;
  color: #fff !important;
}

ul.nav-menu li.menu.open .sub-menu li>a.active,
.jr-card-thumb {
  background-color: #eaddd5 !important;
}

/* new custom css */
.chkre {
  border: 1px solid #80808091;
  border-radius: 4px;
  padding-bottom: 7px;
}

.folo-filter {
  font-size: 14px;
  font-weight: 500;
}

.red-dot-b {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  /* To make it a circle */
  margin-right: 5px;
  vertical-align: middle;
}

.red-dot-b-l {
  background-color: orange;
}

.red-dot-b-m {
  background-color: green;
}

.red-dot-b-h {
  background-color: red;
}

.status-code11 {
  font-size: 14px;
  font-weight: 500;
}

.cl-text {
  text-align: center;
  margin-top: 18px;
  margin-bottom: 0px;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
}

.med-date {
  color: #000;
  font-size: 14px;
}

.c-pointer {
  cursor: pointer;
}

.lot-name {
  font-size: 16px;
  font-weight: 500;
}

.m10 {
  margin: 10px 10px 0px;
}

.filter-text {
  font-size: 16px;
  font-weight: 500;
}

.mt80 {
  width: 97%;
  margin-top: 10px;
}

.mt67 {
  width: 62%;
  margin-top: 10px;
}

.status-container-status14 {
  color: rgb(255, 255, 255);
  background: #54bb5b;
  padding: 5px 8px !important;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
}

.revenue-status-14 {
  color: rgb(255, 255, 255);
  background: #f8961d;
  padding: 5px 8px !important;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
}

.heading-lines-privacy {
  font-size: 19px;
  color: #000;
  letter-spacing: 0;
  font-weight: 500;
  line-height: 2.5;
  text-decoration: underline;
}

.nj8 {
  padding: 10px 10px 4px;
  margin-bottom: 0px;
}

.sdfs {
  font-size: 14px;
  font-weight: 600;
  margin-right: 5px;
  color: black;
}

.SfeR {
  font-size: 11px;
  font-weight: 400;
}

.firstletter-box {
  width: 35px;
  height: 35px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  color: #fff;
  font-size: 16px;
  margin: 10px 5px;
  line-height: 34px;
}

.color-oiu {
  background: #ff5722;
}

.color-piu {
  background: #673ab7;
}

.color-giu {
  background: grey;
}

.color-biu {
  background: #197eeb;
}

.color-miu {
  background: #eb1949;
}

.reply-send {
  color: #fff;
  background: #197eeb;
  border: 1px solid #197eeb;
  margin: 10px;
}

.file-upload-r {
  margin: 5px 6px 0px;
}

.reply-msg-box {
  border: none;
  background: #8b82821a;
  margin: 0;
  padding: 7px 34px;
  font-size: 14px;
  color: inherit;
  width: 100%;
  height: 44px;
  margin-top: auto;
}

.reply-list-box {
  background: #f3f2f28a;
  padding: 5px 10px;
  border: 1px solid #8080803d;
  margin: 0px 5px;
}

.hidden-file {
  display: none;
}

.custom-file-upload {
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}

.custom-file-upload:hover {
  text-decoration: underline;
}

.p-r-div {
  position: relative;
}

.reply-box-span {
  position: absolute;
  top: 6%;
  left: 0%;
  cursor: pointer;
}

.reply-box-span-i {
  font-size: 17px !important;
  font-weight: 600 !important;
}

.reply {
  margin-left: 19px;
  text-decoration: underline;
  cursor: pointer;
}

.manager-name {
  padding: 10px 10px 0px;
}

.assign-btn {
  cursor: pointer;
  background: #083266;
  color: #fff;
  padding: 5px 4px;
  text-align: center;
  font-size: 12px;
}

.reasign-box {
  margin: 10px 20px 20px 13px;
}

.reasign {
  cursor: pointer;
  background-color: #f8961d;
  font-size: 13px;
  color: #ffff;
  padding: 5px;
  box-shadow: 2px 0px 1px 1px #f8961d;
  float: right;
}

.asign {
  cursor: pointer;
  color: #0a5dc3c7;
  font-size: 13px;
}

.stamp-required-text {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0px;
  padding: 10px 10px 10px 15px;
}

.general-img-box {
  border: 1px solid #80808047;
  padding: 10px;
  height: 120px;
  width: 120px;
  position: relative;
  margin: 5px;
}

.general-img-box span {
  position: absolute;
  background: red;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  z-index: 111;
  border: red;
  text-align: center;
  color: #fff;
  margin: -2px;
  cursor: pointer;
}

.general-img-box img {
  width: 100%;
  height: 100%;
}

.fc .fc-daygrid-more-link {
  background: #f8961d;
  margin-left: 10px !important;
  padding: 6px 5px 5px;
  border-radius: 50%;
  font-size: 10px;
  color: #fff !important;
  border: #000;
  display: inline-block;
  width: 25px;
  height: 25px;
  text-align: center;
  margin-bottom: 10px;
}

.event-title {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 0px;
}

.fc .fc-more-popover .fc-popover-body {
  overflow-y: scroll;
  height: 250px;
}

.arrow-conainer {
  cursor: pointer;
}

.hr-lines span svg {
  cursor: pointer;
}

.approval-count {
  background: black;
  margin-left: 10px;
  padding: 2px;
  border-radius: 50%;
  font-size: 10px;
  color: #fff;
  border: #000;
  display: inline-block;
  width: 20px;
  height: 20px;
  text-align: center;
}

.approval-notif-count {
  background: #ffc107;
  padding: 2px;
  border-radius: 50%;
  font-size: 10px;
  color: #fff;
  border: #000;
  display: inline-block;
  text-align: center;
  width: 20px;
  height: 20px;
  position: relative;
  left: -5px;
  bottom: 14px;
}

.message-us-box {
  background: #efe1e1;
  padding: 10px;
  writing-mode: vertical-lr;
  position: fixed;
  /* Use fixed positioning for better control */
  top: 50%;
  /* Center vertically */
  right: 0;
  /* Align to the right */
  transform: translateY(-50%);
  /* Adjust for exact centering */
  z-index: 1;
  color: #083266;
  height: auto;
  /* Adjust height if necessary */
  width: 40px;
  /* Set a specific width */
  white-space: nowrap;
  /* Prevent text wrapping */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  /* Optional: Add some styling */
  cursor: pointer;
  border-radius: 6px 0px 0px 6px;
}

.popup-chat {
  background: #fff;
  position: fixed;
  bottom: 0;
  right: 2vw;
  z-index: 1;
  min-height: 260px;
  width: 28%;
  box-shadow: 1px 1px 5px 2px grey;
}

.notif-pop-up {
  background: #fff;
  position: fixed;
  top: 8vh;
  right: 2vw;
  z-index: 1;
  min-height: 260px;
  color: black;
  width: 31%;
  box-shadow: 1px 1px 5px 2px grey;
}

.notif-h-cont {
  padding: 10px;
  font-size: 15px;
  background: #8080801c;
  font-weight: 500;
}

.notif-h-cont i {
  cursor: pointer;
  font-weight: 600;
}

.popup-chat p {
  background: #083266;
  padding: 10px;
  color: #fff;
  font-size: 14px;
  font-family: "Roboto";
}

.notif-welcome-cont {
  padding: 40px;
  height: 180px;
}

.notif-welcome-cont p {
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  margin-top: 30px;
}

.notif-cont-family {
  font-family: "Roboto", sans-serif;
}

.notif-grid-cont {
  /* display: grid;
  grid-template-columns: repeat(3, 1fr); */
  padding: 14px 0px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  border-top: 1px solid #8080806e;
}

.notif-grid-cont-span {
  width: 33%;
  display: inline-block;
  text-align: center;
}

.notif-grid-count {
  background: #5e2dd5;
  color: #fff;
  text-align: center;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: inline-block;
  font-size: 13px !important;
  margin-right: 4px;
}

.close-btn-chat {
  float: right;
  font-size: 18px;
  cursor: pointer;
}

.chat-write-us-box {
  color: #555;
  position: fixed;
  bottom: 0;
  width: 28%;
  padding: 2px;
  border: 1px solid #94949459;
  outline: 0;
}

.gn-modal {
  width: 500px !important;
}

.generateCerti {
  width: 500px !important;
}

.chat-write-us-box textarea {
  border: none;
  background: transparent;
  margin: 0;
  padding: 7px 8px;
  font-size: 14px;
  color: inherit;
  border-radius: inherit;
  width: 80%;
}

.chat-write-us-box input[type="text"]::placeholder {
  color: #bbb;
}

.chat-write-us-box span[type="submit"] {
  text-indent: -999px;
  overflow: hidden;
  width: 40px;
  padding: 0;
  margin: 0;
  border: 1px solid transparent;
  border-radius: inherit;
  background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat center;
  cursor: pointer;
  opacity: 0.7;
}

.chat-write-us-box span[type="submit"]:hover {
  opacity: 1;
}

.chat-write-us-box span {
  font-size: 20px;
  cursor: pointer;
  float: right;
  margin-right: 1vw;
  padding-top: 10px;
}

.chat-write-us-box span img {
  width: 30px;
}

.chat-write-us-box span[type="submit"]:focus,
textarea:focus {
  box-shadow: none !important;
  border: none !important;
  outline: none;
}

.per-table1 td {
  padding: 10px;
}

.check-label7 {
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
}

.super-checkbox-permision {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.text678 {
  font-size: 18px;
  font-weight: 500;
  margin-top: 10px;
}

.net-chart {
  padding-left: 10px !important;
  padding-right: 0px !important;
  min-height: 0px !important;
}

.jr-card {
  padding: 14px !important;
}

.jr-card-body {
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  flex-direction: column;
}

.listing-main {
  border: 1px solid lightgrey;
  border-radius: 4px;
  margin-bottom: 1rem !important;
}

.right-unlist {
  background: rgb(244, 245, 247);
  /* padding: 15px; */
  border-radius: 3px;
}

.right-unlist-1 {
  background: rgb(244, 245, 247);
  padding: 0px 15px;
  border-radius: 3px;
}

.make-aapbtn {
  background: #dfe7f6;
  border-radius: 5px;
  padding: 4px 8px;
  font-family: Segoe UI !important;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #2c58a0 !important;
  margin-right: 5px;
  cursor: pointer;
  border: 0;
}

.listing-main .main-title {
  color: #363636;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.125;
  font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial,
    sans-serif !important;
}

.listing-main span {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.125;
  font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial,
    sans-serif !important;
}

.listing-main .sub-title {
  color: #363636;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1.125;
  font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial,
    sans-serif !important;
}

.client-listing .stage-con {
  font-size: 0.795rem;
}

.stage-con {
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #505f79;
  font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial,
    sans-serif !important;
  font-style: normal;
  margin-right: 10px;
}

.client-listing .dis-con {
  font-size: 0.85rem;
}

.dis-con {
  font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial,
    sans-serif !important;
  font-style: normal;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  color: #010a1a;
  overflow-wrap: break-word;
}

.file-upload-cus {
  display: block;
  text-align: center;
  margin: 9px 0px 24px;
}

.file-upload-cus .file-select {
  display: block;
  border: 2px solid #dce4ec;
  font-weight: 500;
  color: #34495e;
  cursor: pointer;
  height: 40px;
  line-height: 40px;
  text-align: left;
  background: #ffffff;
  overflow: hidden;
  position: relative;
}

.file-upload-cus .file-select .file-select-button {
  background: #dce4ec;
  padding: 0 10px;
  display: inline-block;
  height: 40px;
  line-height: 40px;
}

.file-upload-cus .file-select .file-select-name {
  line-height: 40px;
  display: inline-block;
  padding: 0 10px;
}

.file-upload-cus .file-select input[type="file"] {
  z-index: 100;
  cursor: pointer;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  filter: alpha(opacity=0);
}

.header-refresh {
  margin-right: 16px;
  color: #fff;
  cursor: pointer;
  margin-top: 4px;
}

.header-refresh i {
  font-size: 21px;
  font-weight: 600;
}

.excel-heading1 {
  font-size: 14px;
  font-weight: 500;
}

.excel-heading1-con {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding-top: 10px;
  border: 1px solid #80808017;
  box-shadow: 2px 2px 3px -3px;
}

.time-leftcontent p {
  color: #344563;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
  margin-top: 10px;
}

.order-btn {
  color: #00947e;
  background: #00947e30;
  border-radius: 40px;
  padding: 10px;
}

.time-rightcontent {
  display: flex;
}

.prev-div {
  background: #f4f5f7;
  border-radius: 3px;
  padding: 2px 10px;
  font-size: 14px;
  font-weight: 500;
}

.prev-div p {
  margin-bottom: 10px;
  margin-top: 10px;
}

.whats-status {
  background: #25d3661a;
}

.p-8o {
  padding: 80px;
}

.modal-cancel12 {
  margin: 2px 14px;
  font-size: 20px;
  font-weight: 600;
  color: grey;
  cursor: pointer;
}

.timline-content {
  background: #fff;
  box-shadow: 1px 0 1px rgba(9, 30, 66, 0.25), 0 0 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  padding: 16px 16px;
  margin-top: 12px;
  border: 1px solid #80808030;
}

.time-name {
  border-bottom: 1px solid #ebecf0;
}

.time-name input {
  width: 13px;
  height: 18px;
}

.time-name h4 {
  font-size: 16px;
  font-weight: 500;
  color: #004281;
  display: inline-block;
  line-height: 24px;
}

.text-dt {
  color: #7a869a;
  font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial,
    sans-serif !important;
  font-size: 13px;
}

.MuiButton-root {
  font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial,
    sans-serif !important;
}

h3 {
  font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial,
    sans-serif !important;
}

.downloadstatus {
  background: #dfe7f6;
  border-radius: 5px;
  padding: 4px 8px;
  height: 30px !important;
  font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial,
    sans-serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: #2c58a0;
  margin-right: 5px;
  cursor: pointer;
  border: 0;
  width: auto;
  margin-left: 10px;
}

.article12 {
  padding: 5px;
  border: 1px solid #ccc;
  text-align: left;
  font-size: 13px;
  color: #212121;
  background-color: #c2c2c2;
  line-height: 14px;
}

.registered12 {
  padding: 6px;
  border: 1px solid #ccc;
  text-align: left;
  font-size: 14px;
  color: #777;
  line-height: 14px;
  font-weight: 600;
}

.event12 {
  display: grid !important;
  grid-template-columns: repeat(2, 1fr);
  font-size: 14px;
  font-weight: bold;
  color: #000;
}

.mt-2o {
  margin-top: 20px;
}

.notices-select .MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
  right: 16vw;
  position: fixed;
  width: 200px;
  left: 0 !important;
}

.wrapper {
  max-width: 1080px;
  /* margin: 50px auto; */
  padding: 10px 0px;
  position: relative;
}

.mob-view {
  visibility: hidden;
}

.inner .wrapper .center-line {
  left: 8%;
}

.wrapper .center-line {
  position: absolute;
  height: 100%;
  width: 1px;
  background: #dbdbdb;
  left: 50%;
  top: 20px;
  transform: translateX(-50%);
}

.wrapper .center-line:before {
  content: "";
  position: absolute;
  width: 24px;
  height: 24px;
  right: -12px;
  background-color: #4a4a4a;
  border: 7px solid #dbdbdb;
  top: 15px;
  border-radius: 50%;
  z-index: 1;
}

.inner .wrapper .row section {
  width: calc(84% - 0px);
  padding-left: 17px !important;
}

.wrapper .row section {
  background: #fff;
  border-radius: 10px;
  width: calc(50% - 40px);
  padding: 20px;
  position: relative;
  box-shadow: 0 0 20px #ddddddd7;
}

.timeline-sec {
  box-shadow: none !important;
  padding: 10px 5px !important;
}

.wrapper .row-2 {
  justify-content: flex-end;
}

.footer-ethos-text {
  color: #fff;
}

.footer-ethos-text a {
  color: #fff;
}

.copy-btn {
  background: #1c1819 !important;
  color: #fff !important;
  padding-top: 7px !important;
  /* margin-left: 12px !important; */
  height: 28px;
  text-transform: capitalize !important;
  font-size: 12px !important;
}

.w-ty {
  width: 250px !important;
  margin-top: 6px;
}

.copytext12 {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0px;
  /* margin-top: 5px; */
}

.copy-text-container {
  border: 1px solid #80808017;
  padding: 10px;
  box-shadow: 1px 1px 2px 1px #808080ad;
  margin-bottom: 16px;
}

.claendar-container {
  background: #fff;
  padding: 10px;
  border-radius: 6px;
  margin-bottom: 16px;
}

.fc .fc-view-harness {
  height: 429.593px !important;
}

.modal-case-12-heading {
  font-size: 16px;
  font-weight: 500;
}

.n--data {
  font-size: 16px;
  font-weight: 400;
}

.generate-disabled {
  opacity: 0.65;
  box-shadow: none;
}

.notices-heading {
  font-size: 16px;
  font-weight: 500;
}

.notice-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.tracking-tabs-cont {
  margin: 10px 0px;
  background: #80808021;
}

.tabs-tracking {
  border-bottom: unset !important;
}

.tabs-tracking_active {
  border-bottom: 2px solid #1c1819 !important;
}

.tabs-tracking_active1:hover {
  border: unset !important;
}

.tracking-status-container-text {
  color: rgb(255, 255, 255);
  /* text-transform: uppercase; */
  padding: 5px 8px !important;
  margin-top: 17px !important;
  border-radius: 4px;
  font-size: 12px;
  background: grey;
}

.sent {
  color: grey !important;
}

.delivered {
  color: orange !important;
}

.red {
  color: red !important;
}

.seen {
  color: #2e7af4 !important;
}

.st-font-pov {
  font-size: 12px !important;
}

.upload-text34h {
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
}

.filestatus-povstBoxes {
  margin: 10px;
}

.filestatus-povstBoxes p {
  color: #344563;
  font-weight: 500;
  font-size: 14px;
  margin: 0px;
}

.buton-conatiner243 {
  display: flex;
  margin: 20px;
}

.one-btn-gnrate {
  width: 20%;
  padding: 10px;
  /* border: 1px solid #80808045; */
  margin: 10px;
}

.one-btn-tmplate {
  width: 36%;
  padding: 10px;
  /* border: 1px solid #80808045; */
  margin: 10px;
}

.one-btn-arbit {
  width: 26%;
  padding: 10px 0px;
  /* border: 1px solid #80808045; */
  margin: 10px;
}

.gnrate123 {
  background: #1c1819c7;
  color: #fff;
  border: #1c1819c7;
  padding: 8px 10px;
  width: 150px;
  font-size: 12px;
  font-weight: 400;
}

.gnrate12As {
  background: #2b4c65;
  border: #2b4c65;
  color: #fff;
  width: 122px;
  border: #cfebe7;
  padding-bottom: 5px;
  height: 32px;
  font-size: 12px;
  font-weight: 400;
}

.gnratemp123 {
  background: #cfebe7;
  color: #00947e;
  width: 124px;
  border: #cfebe7;
  font-size: 12px;
  font-weight: 400;
  padding-bottom: 5px;
  height: 32px;
}

.gnrtaudit123 {
  background: #3f51b5;
  color: #fff;
  width: 154px;
  border: #cfebe7;
  font-size: 12px;
  font-weight: 400;
  padding-bottom: 5px;
  height: 32px;
}

.gnrtaudit1234 {
  background: #e01f27b5;
  color: #fff;
  width: 154px;
  border: #cfebe7;
  font-size: 12px;
  font-weight: 400;
  padding-bottom: 5px;
  height: 32px;
}

.stage123 {
  background: #4caf50;
  color: #fff;
  width: 154px;
  border: #cfebe7;
  font-size: 12px;
  font-weight: 400;
  padding-bottom: 3px;
  height: 32px;
}

.gnrtcertified123 {
  background: #5a4b1c;
  color: #fff;
  width: 154px;
  border: #cfebe7;
  padding: 8px 10px;
  font-size: 12px;
  font-weight: 400;
  padding-bottom: 5px;
  height: 32px;
}

.gnrtheader123 {
  background: #f06c99;
  color: #fff;
  width: 125px;
  border: #cfebe7;
  font-size: 12px;
  font-weight: 400;
  padding-bottom: 5px;
  height: 32px;
}

.box-rev-123 {
  display: flex !important;
  justify-content: space-evenly;
}

.gnrtarbit123 {
  background: #199f8b;
  color: #fff;
  width: 122px;
  border: #cfebe7;
  padding-bottom: 5px;
  font-size: 12px;
  font-weight: 400;
  height: 32px;
}

.gnrate-select {
  margin-top: 6px;
  font-size: 15px;
  color: grey;
}

.tempalte-form1 {
  width: 290px;
  margin: auto;
}

.submit-gnrate3 {
  background: #f06c99;
  color: #fff;
  border: #cfebe7;
  padding-bottom: 5px;
  height: 32px;
  width: 100%;
  margin-top: 6px;
}

.errormsg {
  color: #db1e1e;
  margin-left: 15px;
  font-size: 12px;
}

.addtemp {
  background: #1c1819;
  color: #fff;
  border: #1c1819;
  width: 170px;
  padding: 10px 6px;
  font-size: 12px;
  font-weight: 400;
  margin: 10px;
}

.scroll-list1 {
  height: 300px;
  overflow-y: scroll;
  width: 100%;
}

.scroll-list1::-webkit-scrollbar {
  width: 6px;
}

.scroll-list1::-webkit-scrollbar-track {
  background: none;
}

.scroll-list1::-webkit-scrollbar-thumb {
  background: none;
}

.scroll-list1::-webkit-scrollbar-thumb:hover {
  background: none;
}

.header-grid-design {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  background: #80808038;
  border: 1px solid #80808042;
  padding: 6px 6px 1px;
  color: #000;
  font-weight: 400;
  margin: 5px;
  height: 44px;
  width: 232px;
}

.header-design-2 {
  width: 146px;
  height: 40px;
  padding-top: 6px;
  font-size: 12px;
}

.grid-filter-cn {
  width: 114px;
  margin-top: 4px;
  margin-left: 6px;
  font-weight: 400;
}

.grid-filter-txt {
  margin: 5px;
  font-size: 12px;
  color: #000;
}

.grid-filter-txt-i {
  font-size: 17px;
  margin: 5px;
  color: #000;
}

.page-select12-m {
  margin-left: 7px;
  font-weight: 400;
  color: #000;
  font-size: 13px;
  margin-right: 7px;
  border-radius: 6px;
  border: 1px solid #7a869a7d;
  height: 29px;
}

.pagination-text-m {
  font-weight: 400;
  font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial,
    sans-serif !important;
  line-height: 20px;
  color: #000;
  font-size: 12px;
}

.allow-dis-btn {
  color: #fff;
  border: 1px solid #f06c99;
  height: 38px;
  font-size: 14px;
  font-weight: 400;
  border-radius: 2px;
  background: #f06c99;
  width: 110px;
}

.dis-allow-btn {
  background: #1c1819;
  color: #fff;
  border: 1px solid #1c1819;
  height: 38px;
  font-size: 14px;
  font-weight: 400;
  border-radius: 2px;
  width: 122px;
}

.b-u {
  border-bottom: unset !important;
}

.add-cd-text {
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  margin: 10px;
  text-transform: uppercase;
}

.back-btn-arw1 {
  color: grey;
  font-size: 19px;
  float: left;
  border: unset;
  background: unset;
}

.notesTabs-btn {
  background: #1c1819;
  color: #fff;
  border: 1px solid #1c1819;
  font-size: 14px;
  font-weight: 400;
  margin: 0px 5px 10px;
  padding: 5px 10px;
  width: 16%;
}

.notes-tab-list {
  margin: 10px 0px;
  border: 1px solid #80808029;
  box-shadow: 1px 0 1px rgba(9, 30, 66, 0.25), 0 0 1px rgba(9, 30, 66, 0.31);
  border-radius: 5px;
}

.textadditor {
  margin: 20px 10px;
  color: #2171c8;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

.no-shipment {
  font-size: 16px;
  margin-left: 20px;
  margin-top: 10px;
  font-weight: 400;
}

.vc-link {
  font-size: 14px;
  font-weight: 600;
  color: #091e42;
}

.preview-pdf3 {
  margin-right: 20px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
}

.preview-pdf34 {
  color: #4a4a4a;
  font-size: 14px !important;
  margin: 3px;
  cursor: pointer;
}

.whtsap-dwn {
  background: #fff;
  border: 1px solid #808080d9;
  padding: 3px 10px;
  margin: 5px;
  border-radius: 5px;
  font-weight: 500;
}

.excel34-dwn {
  background: #fff;
  /* border: 1px solid #808080a8; */
  padding: 3px 10px;
  margin-top: 2px;
  border-radius: 5px;
  font-weight: 400;
  cursor: pointer;
  color: #7a869a;
}

.brdr-1px {
  border: 1px solid #80808099;
}

#pdf-download {
  display: none;
}

/* .jGqOvK {
  cursor: not-allowed;
} */
.dTuydp {
  display: none !important;
}

.ccTCgZ {
  display: none !important;
}

.yes-no-09 {
  border: unset;
  background: unset;
  color: #0000ffa6;
  font-size: 16px;
  margin-right: 10px;
}

.sc-fUnMCh {
  user-select: none;
  /* -moz-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
        pointer-events: none; */
}

/* tab css */
.w-brk1 {
  white-space: break-spaces;
}

#js-timeline .wrapper:nth-child(even) .row {
  justify-content: flex-end;
}

#js-timeline .wrapper .row .timeline-box {
  /* display: grid; */
  display: block;
  width: 405px;
  background: #8080800f;
  padding: 20px;
  white-space: pre-line;
}

.js-msg-title {
  border: 1px solid #d5d5d5;
  padding: 5px 10px;
  margin: 10px 0 5px;
  cursor: pointer;
}

/* .js-msg-status {
  border: 1px solid #d5d5d5;
  padding: 10px 5px;
  overflow: hidden;
  display: none;
  height: 0;
} */

.js-msg-status {
  border: 1px solid #d5d5d5;
  padding: 10px 5px;
  overflow: hidden;
  display: none;
  height: 0;
}

.js-msg-status.active {
  display: block;
  height: auto;
  white-space: pre-line;
}

#js-timeline .wrapper .row .timeline-box b {
  text-transform: capitalize;
}

.download-templbrtn {
  margin-top: 10px;
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
}

.fil56 {
  width: 40%;
}

.search56 {
  margin: 1px;
  font-size: 22px;
  cursor: pointer;
  color: grey;
}

.se12 {
  margin-right: 16px;
  color: #7a869a;
  cursor: pointer;
  margin-top: 5px;
  font-size: 14px;
  font-weight: 400;
}

.box-serch-filter0 {
  background: #fff;
  border: 1px solid #8080802e;
  padding: 6px;
  border-radius: 5px;
  box-shadow: 1px 0 3px rgba(9, 30, 66, 0.25), 0 0 1px rgba(9, 30, 66, 0.31);
  margin-top: 10px;
}

.f-srch-i {
  border: unset;
  background: unset;
  font-size: 20px;
  color: grey;
}

/* tab css end*/

/* India post css */
.w3-blue11 {
  background: #303a67;
  color: #fff;
}

.img-left {
  text-align: left;
}

.indicate-text {
  font-size: 14px;
  margin: 0px;
  color: #000000;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  margin-right: 20px;
}

.mainsec-div {
  padding: 0px 50px;
}

.consgnmnt-cont {
  padding: 15px 15px 20px;
  border-radius: 5px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
}

.consgnmnt-sub-cont {
  padding: 4px 10px 2px;
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  width: 50%;
  margin-left: 22px;
  color: #b1b1b1;
  opacity: 1;
}

.b-0p {
  display: flex;
}

.b-0p0 {
  display: flex;
  justify-content: space-between;
}

.rgt98 {
  text-align: center;
  width: 30%;
  margin-top: 5px;
}

.mt-0o {
  margin-top: 20px;
}

.footer-container {
  padding: 0 0 50px 0;
  background-color: #fff;
  color: #4b4c4d;
  margin-top: 50px;
  clear: both;
}

.ms-track-field {
  word-wrap: break-word;
}

.footer-container ul {
  padding: 0;
  list-style: none;
  line-height: 1.6;
  font-size: 14px;
  margin-bottom: 0;
}

.footer-container ul a {
  color: inherit;
  text-decoration: none;
  opacity: 0.8;
  font-size: 14px !important;
  font-weight: 400;
}

.footer-container h3 {
  margin-top: 0;
  margin-bottom: 12px;
  font-weight: bold;
  font-size: 16px !important;
}

.footer-container .item.social {
  text-align: center;
}

.footer-container .item.social>a {
  border: 0px solid #ccc !important;
  opacity: 0.75;
}

.footer-container .item.social>a {
  font-size: 24px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  border: 1px solid #ccc;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 22px;
  color: inherit;
  opacity: 0.75;
}

.track-more {
  color: #ffffff !important;
  background-color: #a42823 !important;
  border-color: #d43f3a !important;
  border-radius: 4px;
  padding: 4px;
  cursor: auto;
}

.track-heading {
  color: #a42823;
  font-size: 18px;
  font-weight: 600;
}

table.responsivetable {
  width: 100%;
  border-collapse: collapse;
  margin: 0;
  vertical-align: top;
  height: auto;
  top: 0;
  position: relative;
}

.fixed-doc-tab1 {
  font-size: 15px;
  width: 25%;
  white-space: pre-line;
}

.fixed-odr-tab1 {
  font-size: 15px;
  width: 32%;
  white-space: pre-line;
}

.responsivetable tr:nth-of-type(odd) {
  background-color: #d6d2d2;
}

.responsivetable th {
  padding: 5px;
  border: 1px solid #ccc;
  text-align: left;
  font-size: 13px;
  color: #212121;
  background-color: #c2c2c2;
  line-height: 14px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
}

.responsivetable td {
  /* padding: 6px;
  border: 1px solid #ccc;
  text-align: left;
  font-size: 12px;
  color: #333;
  line-height: 14px; */
  padding: 6px;
  border: 1px solid #ccc;
  text-align: left;
  font-size: 12px;
  color: #000000;
  font-weight: 500;
  line-height: 14px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
}

.cont-w {
  width: 25%;
  float: left;
}

.e56 {
  color: #000000;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
}

@media only screen and (max-width: 767px) {
  .switch {
    width: 48px !important;
  }

  .toggle-slide:before {
    left: -2px !important;
  }

  .first-l-box {
    width: 20px;
    height: 20px;
    background: #fff;
    text-align: center;
    border-radius: 50%;
    color: #00000087;
    font-size: 14px;
    font-weight: 600;
  }

  .desk-view {
    display: none;
  }

  .mob-view {
    visibility: visible;
  }

  .make-aapbtn {
    background: unset;
    border-radius: 5px;
    font-style: normal;
    font-family: Segoe UI;
    font-size: 14px;
    font-weight: 600;
    line-height: 18.62px;
    text-align: left;
    color: #a02941 !important;
    cursor: pointer;
    border: 0;
  }

  ul.nav-menu>li.open>a,
  ul.nav-menu>li>a:hover,
  ul.nav-menu li.menu>a:hover:before {
    color: #a1a1a1 !important;
  }

  .popup-chat {
    width: 96%;
  }

  .chat-write-us-box {
    width: 96%;
  }

  /* Custom CSS for FullCalendar header responsiveness */
  .calendar-container .fc-header-toolbar {
    display: flex;
    flex-direction: column;
    /* Stack elements vertically */
    align-items: center;
  }

  .calendar-container .fc-toolbar-chunk {
    width: 100%;
    text-align: center;
    margin-bottom: 0.5rem;
    /* Space between toolbar chunks */
  }

  .calendar-container .fc-toolbar-chunk:nth-child(2) {
    font-size: 1.1rem;
    /* Adjust title font size if needed */
  }

  .fc .fc-toolbar-title {
    font-size: 1.1em;
  }

  .fc .fc-toolbar.fc-header-toolbar {
    margin-bottom: 1em;
  }

  .check-po-icon {
    margin-right: 12px;
  }

  .notesTabs-btn {
    margin: 0px 0px 3px;
    width: 100%;
  }

  .upload-text34h {
    text-align: left;
    white-space: normal !important;
  }

  .add-case-header-text {
    padding: 20px 24px !important;
  }

  .snk-j-normal {
    justify-content: center !important;
  }

  .buton-conatiner2431 {
    overflow: scroll;
  }

  .gn-modal {
    width: 96% !important;
  }

  .generateCerti {
    width: 96% !important;
  }

  .scrol-btn-genrte {
    margin-right: 10px;
  }

  .document-d-flex {
    display: block !important;
  }

  .document-d-flex p {
    margin: 8px !important;
  }

  .document-d-flex span {
    padding-top: 5px;
  }
}

@media screen and (min-device-width: 300px) and (max-device-width: 768px) {
  .timline-content {
    background: #fff;
    box-shadow: 1px 0px #80808030;
    border-radius: 4px;
    padding: 16px 13px;
    margin: 12px 6px 2px;
    border: 1px solid #80808030;
  }

  #js-timeline .wrapper .row .timeline-box {
    width: 147px;
  }

  .textsenter {
    text-align: center;
  }

  .mainsec-div {
    padding: 0px !important;
  }

  table.responsivetable {
    border: unset;
  }

  .cont-w {
    width: unset;
    float: unset;
  }

  .event12 {
    display: block !important;
  }
}

@media only screen and (max-width: 600px) {

  .table.responsivetable,
  table.responsivetable thead,
  table.responsivetable tbody,
  table.responsivetable tbody th,
  table.responsivetable tbody td,
  table.responsivetable tbody tr {
    display: block;
  }

  table.responsivetable tbody tr,
  table.responsivetable2 tbody tr {
    border-right: 1px solid rgb(204, 204, 204) !important;
    border-left: 1px solid rgb(204, 204, 204) !important;
    margin-bottom: 5px !important;
  }
}

/* India post css end */

/* landing page */
.categories-section {
  /* background-image: url('assets/images/banner.webp'); */
  background-size: cover;
  height: 500px;
  background-color: #000;
}

.categories-heading {
  color: #fff;
  text-align: center;
  /* font-family: Montserrat; */
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 30px;
  /* padding-left: 168px; */
}

.categories-list {
  display: inline-flex;
  list-style: none;
}

ul.categories-list li {
  border-right: 1px solid #747070;
}

.categories-list-heading {
  color: #fff;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 31px;
  text-transform: uppercase;
}

.categories-text {
  color: #fff;
  text-align: center;
  font-size: 19px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
  padding: 10px;
}

.readmore-btn:hover {
  color: #fff;
}

.switch {
  position: relative;
  display: inline-block;
  width: 56px;
  height: 26px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 22px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
}

.toggle-slide {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.toggle-slide:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 22px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
  transform: translateX(26px);
}

input:checked+.slider {
  background-color: #2196f3;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

input:checked+.toggle-slide {
  background-color: #2196f3;
}

input:checked+.toggle-slide:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.toggle-slide.round {
  border-radius: 34px;
  background: #00a1ff8a;
}

.toggle-slide.round:before {
  border-radius: 50%;
}

.toggle-button {
  padding: 10px 20px;
  background-color: #ddd;
  border: none;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.toggle-button.on {
  background-color: #4caf50;
  color: white;
}

.toggle-button.off {
  background-color: #f44336;
  color: white;
}

.apply-disabled {
  opacity: 0.1;
  cursor: not-allowed !important;
}

/* .scrolled-table{
  overflow-x: scroll;
} */
.w-70 {
  width: 70%;
}

/* landing page end*/
.edit-doc-date-icon {
  font-size: 14px !important;
  margin-left: 10px;
  visibility: hidden;
}

.edit-doc-icon-cont {
  cursor: pointer;
}

.edit-doc-icon-cont:hover .edit-doc-date-icon {
  visibility: visible;
}

.message-box-header {
  margin-top: 20px;
}

.message-box-header span {
  font-size: 18px;
  color: black;
  font-weight: 500;
}

.message-box-header p {
  font-size: 15px;
  font-weight: 500;
  color: #423b3be3;
}

.message-us-list-box {
  padding: 20px 10px;
  border-top: 1px solid #80808073;
  border-bottom: 1px solid #80808073;
  margin-top: 20px;
}

.message-us-list-icon-bx {
  color: #007bff;
  margin: 10px;
}

.message-us-list-icon-bx-icon {
  font-weight: 600;
  font-size: 14px;
}

.message-us-list-bold-text {
  font-size: 14px;
  font-weight: 600;
  color: black;
}

.message-us-list-simple-text {
  font-weight: 500;
}

.message-us-list-simple-text-right {
  float: right;
  margin-right: 20px;
  font-weight: 500;
}

.write-msg-box {
  border: none;
  background: #8b82821a;
  margin: 0;
  padding: 7px 15px;
  font-size: 14px;
  color: inherit;
  width: 100%;
  border-radius: 20px;
  height: 44px;
  margin-top: 6vh;
}

.write-msg-box-span {
  position: absolute;
  top: 48%;
  right: 3%;
  width: 32px;
  cursor: pointer;
}

.receiver-msg {
  display: inline-block;
  /* Allow the width to be determined by content */
  min-width: 50px;
  /* Minimum width for the <p> tag */
  max-width: 390px;
  /* Maximum width for the <p> tag */
  float: right;
  clear: both;
  background: #1173b166;
  padding: 5px 8px;
  border-radius: 10px;
  color: #fff;
  font-size: 16px;
  white-space: pre-line;
}

.sender-msg {
  display: inline-block;
  min-width: 50px;
  max-width: 390px;
  background: #8f959966;
  padding: 5px 8px;
  border-radius: 10px;
  color: #fff;
  font-size: 16px;
  white-space: pre-line;
  clear: both;
}

.chat-box-blank {
  /* background-image: url('assets/images/chatbox.png'); */
  /* height:200px;
  filter: opacity(50%); */
  overflow-y: scroll;
}

.add-case-header-text {
  padding: 20px 60px;
}

.search-certified {
  width: 100%;
  margin: 10px 0px;
  padding: 6px 14px;
  background: #fff;
  border: 1px solid grey;
  font-size: 15px;
  font-weight: 400;
  text-align: left;
  color: grey;
  border-radius: 15px;
}

.gnrate-certi-btn {
  width: 100%;
  background: black;
  color: #fff;
  padding: 8px;
  border: 1px solid black;
  font-size: 14px;
  font-weight: 500;
}

.document-d-flex {
  display: flex;
}

.min-h4 {
  min-height: 500px;
  padding: 40px 0px;
}

.mt-a-5 {
  margin-top: 16px;
}

.form-margin {
  margin: 40px 10px;
}

/* new custom css end */

.app-sidebar .side-nav ul.nav-menu li button,
.app-sidebar .side-nav ul.nav-menu li a {
  padding: 10px 20px 10px 20px;
  color: #000;
}

.app-logo-content.d-flex.align-items-center.justify-content-center {
  /* background-image: url('assets/images/background.jpg'); */
  background-color: #0a0a0a;
}

.MuiListItemIcon-root {
  min-width: 30px !important;
}

.app-sidebar .side-nav {
  background: #f9f5f3 !important;
  /* box-shadow: 5px 10px 18px #ced4da; */
}

div.mini-drawer .app-main-header.custom-color {
  background: #1c1819 !important;
}

.arbitupload-btn45 {
  width: 100%;
  background: #f8961d;
  border: 1px solid #f8961d;
  color: #fff;
  padding: 6px;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 14px;
}

.arbituploadcan-btn45 {
  width: 100%;
  background: #0e0c0c;
  border: 1px solid #0e0c0c;
  color: #fff;
  padding: 6px;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 14px;
}

.left-side1 {
  background: #8080800f;
  padding: 10px;
  color: #000;
  font-size: 14px;
  font-weight: 500;
}

.right-side1 {
  background: #8080800f;
  padding: 10px;
  color: #000;
  font-size: 14px;
  font-weight: 500;
}

.space-67 {
  white-space: pre-wrap;
}

.fw-23 {
  font-weight: 600;
}

.download89 {
  padding: 1px 10px;
  display: inline-block;
  color: #fff !important;
  background: #1c1819;
  border: 0;
  height: 32px;
  border-radius: 2px;
  width: 100px;
}

.timeline-container {
  width: 85%;
  margin: 10px auto;
}

.mt-0p {
  margin: 0px;
}

.app-sidebar ul.nav-menu li.menu.open>a,
.app-sidebar ul.nav-menu li a.active,
.cbutton:hover {
  background: #eaddd5;
  color: #1c1819;
}

.app-sidebar .side-nav ul.nav-menu li a:hover {
  background: #eaddd5;
  color: #1c1819;
}

ul.nav-menu li.menu .sub-menu li>a:hover,
ul.nav-menu li.menu .sub-menu li>a:focus,
ul.nav-menu li.menu.open .sub-menu li>a.active:before,
ul.nav-menu li.menu .sub-menu li>a:focus:before,
ul.nav-menu li.menu .sub-menu li>a:hover:before {
  background-color: #f9f5f3 !important;
  color: #000;
}

label.MuiInputLabel-shrink-200,
label.MuiInputLabel-focused-187 {
  color: #1c1819;
}

.page-heading i.zmdi-hc-fw {
  color: #1c1819;
}

.app-main-content .jr-btn,
button.CBmargin,
.app-login-form button {
  background-color: #083266 !important;
  color: white;
}

.app-main-content .jr-btn:hover,
.app-login-form button:hover {
  background: #1c1819 !important;
  text-decoration: none;
}

.app-container.fixed-drawer .app-main-header {
  background: #083266 !important;
}

div#form-dialog-title {
  background: #1c1819;
}

/* Status labels*/
span.label {
  background: gray;
}

span.confirmed,
span.created {
  background: #ffcb00e0;
}

span.searching {
  background: #e0b91ffc;
}

span.started {
  background: #e0491f;
}

span.inrouteDrop {
  background: #b276e3;
}

span.inroutePick {
  background: #972ded;
}

span.completed,
span.Online,
span.active,
span.approved,
span.Yes,
span.yes,
span.success,
span.one,
span.true {
  background: green;
}

span.cancelled,
span.cancel,
span.rejected,
span.blocked,
span.inactive,
span.No,
span.no,
span.false {
  background: #fd2121;
}

span.DestinationInroute,
span.PickupInroute,
span.inrouteDrop {
  background: #b276e3;
}

span.FindingTrips,
span.inroutePick,
span.FindingDrivers {
  background: #972ded;
}

span.Offline {
  background: #eb4d4d;
}

.uper-multi-row {
  background: #8080800a;
  border-radius: 5px;
  padding: 5px 10px;
  margin-bottom: 5px;
}

.react-time-picker {
  width: 100%;
  height: 32px;
  /* border-bottom: 1px solid #737373; */
}

.react-time-picker * {
  border: 0;
}

label.form-label {
  color: #737373;
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
}

.file-bg {
  background: #004281;
}

.table-responsive-material .blog-profile .user-avatar img {
  height: 60px;
  width: 60px;
  object-fit: cover;
}

.user-profile.blog-profile .user-avatar {
  height: auto !important;
  width: auto !important;
}

.blog-detail-profile img {
  height: 120px;
  width: 120px;
}

.terms-txt {
  text-align: center;
  width: 100%;
  font-size: 18px;
  font-weight: 500;
  color: #000000d9;
}

.undertaking1-list li {
  font-size: 14px;
  font-weight: 500;
  color: #000000d9;
  text-align: left;
}

.undertaking1 {
  font-size: 14px;
  font-weight: 600;
  color: #000000d9;
}

.categories-text1 {
  font-size: 15px;
  font-weight: 500;
  color: #000000d9;
  margin: 10px;
}

.categories-select1 {
  font-size: 13px;
  font-weight: 500;
  color: #000000d9;
  margin: 10px;
}

.mediator-signup-form {
  max-width: 1000px !important;
}

.list {
  margin: 30px;
}

.list .item {
  height: 40px;
  width: 150px;
  box-shadow: 1px 1px 5px #ccc;
  align-items: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background: #f9593c;
  color: #fff;
  font-weight: 500;
}

.menulist .menuitem {
  height: 40px;
  width: 150px;
  box-shadow: 1px 1px 5px #ccc;
  align-items: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background: green;
  color: #fff;
  font-weight: 500;
  margin: 20px;
}

.menulist .menuitem {
  margin-bottom: 20px;
}

.list .item:not(:last-child) {
  margin-bottom: 20px;
}

.vks .list {
  display: flex;
  gap: 30px;
}

.drag_drop h1 {
  padding: 10px;
  text-align: center;
  box-shadow: 1px 1px 5px #ccc;
  margin-top: 18px;
}

.no_data p {
  text-align: center;
  margin-top: 50px;
  font-weight: 500;
}

.customizeModal .modal-content {
  max-width: 650px;
}

.common-btn {
  height: 40px;
  background: #1c1819 !important;
  color: #fff !important;
}

.menulist .menuitem {
  height: auto;
  min-height: 40px;
  padding: 10px;
  background-color: #707070;
  left: 0 !important;
}

.menulist .menuitem.dragging,
.menulist .menuitem.draggingOver {
  top: 0 !important;
  position: relative !important;
}

.menulist .menuitem.draggingOver {
  top: unset !important;
  /* bottom : 0 !important */
}

.InnerCol .item.dragging {
  position: absolute !important;
  left: 0 !important;
  top: 0 !important;
}

.item {
  width: 100px;
  height: 50px;
  position: relative;
  z-index: 1;
  margin-bottom: 10px;
}

.menuitem {
  width: 100px;
  height: 50px;
  margin-bottom: 0px;
}

.dragging {
  z-index: 1000;
  cursor: grabbing;
  position: absolute;
  background-color: lightblue;
  opacity: 0.8;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
}

.scrollable-container {
  overflow-y: auto;
}

.container {
  overflow: hidden;
}

.vks .list .ItemWrpper {
  height: 50px;
}


.close-preview-btn-mediation {
  float: right;
  border: none;
  background: transparent;
  font-size: 23px;
}