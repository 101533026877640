.input-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 10px;
 
}

.i.zmdi.zmdi-search.input-icon {
    font-size: 20px;
}

.notification-box {
    font-size: 25px;
}

.circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #e8e5e3;
    color: black;
}

.zmdi-link {
    font-size: 27px;
}

.notification-box {
    border: 1px solid #edeff6;
    background: #edeff6;
    width: 50px;

}

p.clinet-meet {
    margin: 0px;
    font-size: 18px;
}

.date-added {
    color: #1c1819;
    font-size: 14px;
}


.added-by-text {
    color: rgb(72, 95, 199);

}

.meeting-added-by {
    padding-left: 20px;
}