.circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #e8e5e3;
    color: black;
}

.date-added {
    color: #1c1819;
    font-size: 14px;
}
.table-box{
    border: 1px solid #80808073;
    padding: 10px;
}
.table-notes{
    font-size: 14px;
    font-weight: 600;
}