.container-fluid.footer-ethos {
    text-align: center;
    justify-content: center;
}

.footer-loggo img {
    width: 15%;
}

h4.disclaimer-heading {
    color: #083266;
    font-size: 20px;
    font-weight: 600;
}

p.provide-legal-advice {
    font-size: 15px;
    color: #000000;
    line-height: 25px;
}


a.footre-links {
    color: #000000;
    font-weight: 400;
    font-size: 15px;
}

ul.footer-list {
    padding: 0px;
    list-style: none;
    line-height: 28px;
}

a.footre-links :hover {
    color: black;
    font-weight: bold;

}

.footer-location-image img {
    width: 50%;
    height: 30%;
}

.d-flex.footer-location {
    color: #000000;
    font-size: 15px;
    font-weight: 400;
}

.play-store {
    font-size: 17px;
    color: #000000;
    font-weight: 500;
}

.palystore-image img {
    width: 80%;
    height: 100%;
}


p.stay-connected-text {
    color: #F6851F;
    font-size: 27px;
    font-weight: 600;
}

.pt-2.d-flex.socials-links {
    gap: 35px;
}

.socials-links img {
    width: 11%;
    height: 100%;
}

nav.navbar.fixed-bottom.landing-page-navbar-footer {
    padding: 15px;
}

span.footer-ethos-text {
    font-size: 16px;
    font-weight: 500;
}

@media (max-width: 768px) {
    .footer-loggo {
        text-align: center;
    }

    .pt-2.d-flex.socials-links {
        justify-content: center;
    }

    p.stay-connected-text {
        text-align: center;
    }

    .p.stay-connected-text {
        text-align: center;
    }

    .palystore-image {
        text-align: center;
    }

    .d-flex.footer-playstore-links {
        justify-content: center;
        padding-top: 20px;
    }

    .footer-location {
        text-align: center;
    }

    p.footer-address {
        padding-left: 3px;
    }

    .d-flex.footer-location {
        justify-content: center;
    }

    .footer-loggo img {
        width: 50%;
    }

    .footer-location-image img {
        width: 80%;
        height: 52%;

    }

    h4.disclaimer-heading {
        text-align: center;
    }

    p.provide-legal-advice {
        text-align: center;
    }

    ul.footer-list {
        text-align: center;
    }
}

.fixed-bottom {
    position: absolute !important;

}

nav.navbar.fixed-bottom.landing-page-navbar-footer {
    position: relative !important;
}