.feature-box-mediation:nth-child(2),
.feature-box-mediation:nth-child(3) {
    background-color: #eff6ff !important;
    color: #1e3a5f !important;
}

.feature-box-mediation:nth-child(4) {
    background-color: #1e3a5f !important;
    color: #eff6ff !important;
}

.feature-box-mediation:nth-child(2) h4,
.feature-box-mediation:nth-child(3) h4 {
    color: black !important;
    background-color: #356a8b3c !important;
}

.feature-box-mediation:nth-child(4) .step-arrow {
    border-left: 30px solid #EFF6FF !important;
}

.truce-call-card {
    height: 100px !important;
}

@media (max-width: 768px) {
    .truce-call-card {
        height: 340px !important;
    }
}

.truce-call-heading {
    color: #000000;
    font-weight: 500;
    font-size: 26px;
}


.board {
    color: #5A5A5A;
    font-size: 16px;
    font-weight: 400;
}

.types {
    color: #000000;
    font-size: 25px;
    font-weight: 500;
}


.imagine {
    padding: 0px 28px;
    font-size: 18px;
    font-weight: 500;
}


.heading-mediation {
    font-size: 29px;
    font-weight: 500;
}

.delicate {
    font-size: 18px;
    padding: 0px 28px;
    font-weight: 500;
    color: #5A5A5A;
}

p.board.privacy-info {
    font-size: 16px;
}

.privacy-info {
    font-size: 16px;
}

.protecting {
    padding: 0 150px;
    text-align: center;
}

@media (max-width: 768px) {

    h2.protecting {
        margin-top: 180px !important;
        padding: 0px !important;
    }

}