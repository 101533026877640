.circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #e8e5e3;
    color: black;
}


.by_text {
    font-size: 14px;
    color: black;
}
.date-added {
    color: #1c1819;
    font-size: 14px;
}
.kl41 {
    font-size: 15px;
    color: #1c1819;
    font-weight: 700;
    margin-top: 4px;
}