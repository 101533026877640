.update-btn {
    padding: 5px 35px 5px 29px;
    color: #fff !important;
    border: 1px solid;
    background: #f68520;
    font-size: 20px !important;
    border-radius: 10px !important;
}

.password-field {
    background-color: #fff !important;
}

.field-icon {
    float: right;
    margin-left: -25px;
    margin-top: -27px;
    position: relative;
    z-index: 2;
    font-size: 20px;
    margin-right: 8px;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
    background-image: none !important;
}

.old-pswd {
    border-radius: 10px !important;
}

.password-valid {
    color: cadetblue;
}

.reset-password-text {
    font-size: 18px;
}

.custom-modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
    background: #0000003b;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    height: 100%;
    display: grid;
    align-content: center;
}

.modal-content1 {
    width: 50%;
    display: block;
    margin: 0 auto;
    position: relative;
    flex-direction: column;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: .3rem;
    outline: 0;
}

.password-field-text {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 14px;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.password-field-text:hover {
    background-color: #f8f9fa;
}

.modal-footer {
    display: flex;
    justify-content: center;
}

button.btn.btn-warning.add_claimant.mx-5.MuiButtonBase-root.MuiButton-root.jr-btn.jr-btn-label.left.CBmargin.CBmarginT.M.w-50 {
    width: 33% !important;
}

.cancel-passwor-btn {
    margin-left: 50% !important;
}

.update-password-btn {
    width: 55% !important;
}

@media (max-width: 768px) {
    .modal-content1 {
        width: 98% !important;
    }

    .cancel-passwor-btn {
        margin-left: 25% !important;
    }

    .update-password-btn {
        margin-left: 25% !important;
    }

}