/* sonika */
.sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
    background-image: url(/public/images/navbg.png);
    background-size: cover;
    background-repeat: no-repeat;
  }
  
  .sidenav a {
      padding: 8px 8px 8px 8px;
      text-decoration: none;
      font-size: 20px;
      color: #ffffff;
      display: block;
      transition: 0.3s;
  }
  
  .sidenav a:hover {
    color: #f1f1f1;
  }
  
  .sidenav .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
  }
  .nav-ul-v {
      display: flex;
      flex-direction: column;
      list-style: none;
      align-items: center;
      padding: 0px !important;
  }
   
  @media screen and (min-width:769px){
    .opennav{
      display: none !important;
    }
   
    
  }
  @media screen and (max-width:769px){
  .desktop-show{
    display: none !important;
  }
  .opennav{
    display: flex !important;
  }}