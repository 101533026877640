.dispute h1 {
    text-align: right;
}

/*  */
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
    font-family: "Poppins", sans-serif;
    /* replace font family with required one  */
}


/*  hero banner section css starts  */
.hero-banner-Embrace-sec {
    height: 50vh;
}

.hero-banner-Embrace-sec p {
    font-size: clamp(25px, 4vw, 35px);
    font-weight: 600;
    max-width: 550px;
}

.hero-banner-Embrace-sec button {
    background-color: #083266;
    color: white;
    border: none;
    padding: 13px 30px;
    border-radius: 8px;
    font-size: 16px;
}

.hero-banner-Logo-sec img {
    height: 100px;
    width: 100%;
}

.hero-banner-Logo-sec p {
    font-size: clamp(22px, 4vw, 28px);
    font-weight: 500;
}

/*  hero banner section css ends */

.bordercls {
    border: 1px solid #08326698;
}

.easy-affordable-section {
    background-color: #083266;
    padding: 70px 140px 70px 140px;

}

.easy-affordable-head-color {
    color: orange;
}

.easy-parrah {
    color: white;
    font-size: 20px;
    font-weight: 500;
}

.revolutionize-section {
    padding: 70px 120px 70px 111px;
}

.revolutionize-text {
    font-size: 20px;
    font-weight: 500;
    color: rgb(101, 101, 101);

}

/* .main-width{
    border: 2px solid rgb(188, 187, 187);
} */
/* .just-settle-offers {
padding: 193px 160px 0px 160px;
} */
.just-offers-parrah {
    font-size: 20px;
    font-weight: 400;
}

button.btn-1 {
    border: none;
    background-color: #083266;
    color: white;
    padding: 11px 40px 0px 40px;
    border-radius: 10px;
}


@media only screen and (max-width: 768px) {

    .hero-banner-Embrace-sec {
        order: 2;
    }

    .justice-serve {
        padding-top: 35px;
        text-align: center;
    }

    .hero-banner-Embrace-sec {
        height: auto;
    }

    p.embrace {
        padding: 0px !important;
        text-align: center;
    }

    .hero-banner-contact-us {
        text-align: center;
        padding: 0px !important;
    }

    .easy-affordable-section {
        padding: 20px;
    }

    .just-settle-offers {
        padding: 20px;
    }

    .revolutionize-section {
        padding: 20px;
    }

    .flip-card-row {
        height: 420px !important;
    }

}




/* why choose us section / Our blog Journey  css starts  */


/* .boxx-why-choose {
    position: absolute;
    width: 95% !important;
    height: 300px !important;
} */
.boxx-why-choose {
    position: relative;
    width: 100% !important;
    height: 300px !important;
    max-width: 400px;
}



.flip-card-row {
    display: flex;
    align-items: center;
    position: relative;
    height: 130px;
}

.boxx-why-choose .front {
    position: relative;
    border: 1px solid #083266;
    height: 100px;
    width: 100%;
    border-radius: 20px;
    /* background: #262626; */
    backface-visibility: hidden;
    transform: perspective(1000px) rotateX(0deg);
    font-size: 30px;
    font-weight: 500;
    transition: .3s;
}

.boxx-why-choose .front-secondrow {
    border: 1px solid #083266;
    color: goldenrod;
    background-color: #083266 !important;
}

.boxx-why-choose:hover .front {
    transform: perspective(1000px) rotateX(180deg);
}

.boxx-why-choose .back {
    position: absolute;
    top: 0;
    left: 0;
    padding: 25px 20px;
    font-size: larger;
    text-align: center;
    border-radius: 20px;
    /* height: 120px; */
    width: 100%;
    background: #083266;
    color: goldenrod;
    backface-visibility: hidden;
    transform: perspective(1000px) rotateX(-180deg);
    transition: .3s;
}

.boxx-why-choose .back-secondrow {
    background-color: transparent !important;
    color: black !important;
    border: 1px solid #083266;
}

.boxx-why-choose:hover .back {
    transform: perspective(1000px) rotateX(0deg);
}

.boxx-why-choose .front .center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
}



/* cards section css starts */


.feature-box {
    background-color: #1E3A5F;
    /* height: 100%; */
    position: relative;
    color: white;
    padding: 30px 25px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 350px;
    /* border-radius: 10px; */
}

.feature-box h4:nth-child(1) {
    /* color: goldenrod; */
    color: #FFC107;
}

.feature-box h4:nth-child(2) {
    color: #FFC107;
}

.feature-box h4 {
    background-color: white;
    font-size: 30px;
    width: 50px;
    height: 50px;
    display: flex;
    padding: 0px 18px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.feature-box:nth-child(2),
.feature-box:nth-child(4),
.feature-box:nth-child(6) {
    background-color: #EFF6FF;
    color: #1E3A5F;
}

.feature-box:nth-child(2) h4,
.feature-box:nth-child(4) h4,
.feature-box:nth-child(6) h4 {
    color: black;
    background-color: #356a8b3c;
}

.row.no-gutters {
    margin-right: 0;
    margin-left: 0;
}

.step-arrow {
    width: 0;
    height: 0;
    border-top: 30px solid transparent;
    border-bottom: 30px solid transparent;
    position: absolute;
    left: 0px;
    top: 11%;
    transform: translateY(-50%);
}

.feature-box:nth-child(1) .step-arrow {
    border-left: 30px solid #EFF6FF;
}

.feature-box:nth-child(2) .step-arrow {
    border-left: 30px solid #1E3A5F;
}

.feature-box:nth-child(3) .step-arrow {
    border-left: 30px solid #EFF6FF;
    /* border-left: 30px solid #1E3A5F;  */
}

.feature-box:nth-child(4) .step-arrow {
    border-left: 30px solid #1E3A5F;
}

.feature-box:nth-child(5) .step-arrow {
    border-left: 30px solid #EFF6FF;
}

.feature-box:nth-child(6) .step-arrow {
    border-left: 30px solid #1E3A5F;
}



/* cards section css ends */




/* contact section css starts  */

.contact-section {
    display: flex;
    min-height: 100vh;
}

.contact-section .contact-image {
    background: url('../../../assets/images/HomePage3.png') center center/cover no-repeat;
    flex: 1;
}

.contact-section .contact-form {
    background-color: #1E3A5F;
    color: white;
    padding: 40px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.contact-section .contact-form h2 {
    margin-bottom: 20px;
    color: #fff;
}

.contact-section .form-group {
    margin-bottom: 15px;
}

.contact-section .btn {
    background-color: #FFC107;
    color: #1E3A5F;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
}

button.btn-contact-us {
    background: transparent;
    border: 1px solid #fff;
    border-radius: 13px;
    padding: 9px 24px 7px 19px;
    color: #fff;
    font-size: 16px;
}

p.embrace {
    color: #232323;
    font-size: 35px;
    font-weight: 600;
}

@media (max-width: 768px) {
    .contact-section {
        flex-direction: column;
    }

    button.btn-contact-us {
        margin-top: 11px;
    }

    .contact-us-btn {
        text-align: center;
    }

    .boxx-why-choose {

        height: 80px !important;

    }

    .contact-section .contact-image {
        height: 300px;
    }

    .col-md-6.bordercls.d-flex.justify-content-center.align-items-center img {
        width: 100%;
        padding-top: 10px;
        padding-bottom: 10px;
    }

}


/* contact section css ends  */




/* owl carousel css  */
.post-slide {
    background-color: #ffffff7a;
    width: 100%;
    max-width: 400px;
    padding: 40px;
}

.owlcarousel-sec-classss {
    background-color: rgb(3, 47, 89);
    height: 100vh;
    display: flex;
    align-items: center;
}

.owl-nav {
    float: right;
    font-size: 28px
}

.owl-carousel .owl-nav button.owl-next,
.owl-carousel .owl-nav button.owl-prev,
.owl-carousel button.owl-dot {
    background: 0 0;
    color: inherit;
    border: none;
    padding: 5px 13px !important;
    margin-top: 20px;
    margin-right: 10px;
    font: inherit;
    background: white;
    border-radius: 50%;
}

.owl-controls .owl-buttons .owl-prev,
.owl-controls .owl-buttons .owl-next {
    background: #fff;
    padding: 10px;
    border-radius: 50%;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    margin: 0 5px;
}

.owl-controls .owl-buttons .owl-prev i,
.owl-controls .owl-buttons .owl-next i {
    color: #333;
    font-size: 20px;
}

@media only screen and (max-width: 1280px) {
    .post-slide .post-content {
        padding: 0px 15px 25px 15px;
    }
}

/* owl carousel css ends  */



/* About Us page css starts  */


.about-header {
    position: relative;
    width: 100%;
    height: 300px;
    background: url('https://via.placeholder.com/1500x500') no-repeat center center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
}

.about-header h1 {
    position: relative;
    z-index: 2;
    font-size: 3rem;
}

.about-section {
    background: #f7f8fa;
    padding: 60px 0;
}

.about-content {
    /* max-width: 800px; */
    margin: 0 auto;
    text-align: center;
    color: #333;
}

.about-content p {
    font-size: 18px;
    font-weight: 500;
    color: rgb(97, 97, 97);
}

/* About Us page css Ends  */



/* Truce page css statrs  */


.truce-header {
    position: relative;
    width: 100%;
    height: 300px;

    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
}

.truce-header h1 {
    position: relative;
    z-index: 2;
    font-size: 3rem;
}

.truce-content-section {
    background: #f7f8fa;
    /* Background color matching the image */
    /* padding: 60px 0; */
}

.truce-content-container {
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
    color: #333;
    /* Text color */
}

.truce-content-container h2 {
    font-size: 1.5rem;
    margin-bottom: 20px;
}



/* Truce page css Ends  */

/* css */


.col-md-6.bordercls.d-flex.justify-content-center.align-items-center.py-5 {
    padding: 0px !important;
    margin: 0px !important;
}

.owlcarousel-sec-classss {
    background-color: rgb(3, 47, 89);
    height: 100vh;
    display: flex;
    align-items: center;
}

.post-slide {
    background-color: #ffffff7a;
    width: 100%;
    max-width: 400px;
    padding: 40px;
    margin: 0 auto;
    text-align: center;
}

.carousel-controls button {
    background: none;
    border: none;
    font-size: 28px;
}

.carousel-controls .btn-light {
    padding: 10px;
    border-radius: 50%;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

input#inputFirstName,
input#inputLastName,
input#inputEmail,
input#inputPhone,
textarea#inputMessage {
    border-radius: 15px;
}

p.embrace {
    padding-top: 144px;
    padding-left: 86px;
}

.justice-serve {
    padding-top: 71px;
}

.hero-banner-contact-us {
    padding-left: 77px;
}

h2.easy-affordable-head-color {
    color: #F8961D;
    font-weight: 500;
    font-size: 25px;
}

p.easy-parrah {
    color: #E7E7E7;
    font-size: 18px;
    font-weight: 500;
}

p.revolutionize-text {
    color: #5A5A5A;
    font-size: 18px;
    font-weight: 400;
}

h1.just-offers-head {
    color: #000000;
    font-weight: 500;
    font-size: 26px;
}

p.just-offers-parrah {
    color: #5A5A5A;
    font-size: 18px;
    font-weight: 400;
}

.content-home-page {
    background: #fff !important;
}

h2.why-choose {
    color: #000000;
    font-size: 35px;
    font-weight: 500;
}


p.text-muted {
    color: #5A5A5A;
    font-size: 18px;
    font-weight: 500;
}

.front {
    color: #000000;
    font-weight: 500;
    font-size: 25px;
}