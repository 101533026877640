.col-xs-12 {
    width: 100%;
}

.box {
    position: relative;
    border-radius: 3px;
    background: #ffffff !important;
    /* border-top: 3px solid #d2d6de; */
    /* margin-bottom: 20px; */
    width: 100%;
    /* box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1); */
}

.table-responsive {
    min-height: .01%;
    overflow-x: auto;
}

table {
    background-color: transparent;
    border-color: gray;
}

.table-striped>tbody>tr:nth-of-type(odd) {
    background-color: #f9f9f9;
}

.table>thead>tr>th,
.table>tbody>tr>th,
.table>tfoot>tr>th,
.table>thead>tr>td,
.table>tbody>tr>td,
.table>tfoot>tr>td {
    border-top: 1px solid #f4f4f4;
}

.table>tbody>tr>td,
.table>tbody>tr>th,
.table>tfoot>tr>td,
.table>tfoot>tr>th,
.table>thead>tr>td,
.table>thead>tr>th {
    padding: 8px;
    line-height: 1.42857143;
    vertical-align: top;
    border-top: 1px solid #ddd;
    width: 250px;
}

th {
    text-align: left;
}

.callout.callout-warning,
.alert-warning,
.label-warning,
.modal-warning .modal-body {
    background-color: #f39c12 !important;
}

button.btn.btn-danger.btn-sm.excel-btn {
    padding: 10px;
}

.download-csv-btn {
    width: 40% !important;
    border-radius: 30px !important;
    border: 0px !important;
    padding: 15px !important;

}