/* button.btn.btn-warning.add_claimant.mx-5.MuiButtonBase-root.MuiButton-root.jr-btn.jr-btn-label.left.CBmargin.CBmarginT.M.add-notes {
    width: 30% !important;
}


.order-btn {
    border: 1px;
}

.ck.ck-editor__main {
    height: 70vh !important;
}

.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-blurred {
    height: 70vh;
}

.modal-content {
    width: 50em !important;
    right: 50 !important;
}


.delete-icn {
    font-size: 30px;
}

input.form-control.ml-1 {
    padding: 0px !important;
}

select.d-block.w-100.mx-auto {
    padding: 5px;
}

input.d-block.w-100.mx-auto {
    padding: 5px;
}

.add-notess-btn {
    background: #292627;
    border: 1px;
    color: #fff;
    font-size: 20px;
}

.delte-btn {
    float: right;
}

select#variation-select-1 {
    padding: 8px;
}

select#variation-select-2 {
    padding: 8px;
}

select#variation-select-3 {
    padding: 8px;
}

select#variation-select-4 {
    padding: 8px;
}

select#variation-select-5 {
    padding: 8px;
}

select#variation-select-6 {
    padding: 8px;
}

select#variation-select-7 {
    padding: 8px;
}

select#variation-select-8 {
    padding: 8px;
}

.select.d-block.w-100.mt-4.mx-auto {
    padding: 5px;
}

.input.d-block.w-100.mt-4.mx-auto {
    padding: 5px;
}

@media (max-width: 768px) {
    .modal-content {
        width: 98% !important;
        right: 0 !important
    }
} */

.revenue-status {
    cursor: pointer !important;
}

.view-notes {
    background: #292627;
    cursor: pointer !important;
}

.status-pending {
    background-color: rgb(159 138 133);
    cursor: pointer !important;
}
.error-message{
    color: rgb(211, 55, 55);
    font-size: 12px;
}